<template>
    <div class="container dark">
        <div class="footer">
            <p>Pacific Treasure Foundation is a nonprofit, tax-exempt charitable organization under Section 501(c)(3) of the Internal Revenue Code.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterView',
}
</script>