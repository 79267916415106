<template>
    <div class="nav-home">
        <router-link to="/"><img class="logo" src="../assets/circle-logo.svg"></router-link>
        <router-link to="/newsroom"><h2>Newsroom</h2></router-link>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
}
</script>