<template>
  <div class="landing">
    <h1>PACIFIC TREASURE</h1>
    <p>Preserving our oceans for a sustainable future.</p>
  </div>
  <div class="container white">
    <div class="mission max">
      <h2>OUR MISSION</h2>
      <img src="../assets/dots.svg" alt="" class="dots">
      <p>
        At the Pacific Treasure Foundation, our mission is to protect, preserve, and enhance the vibrant marine ecosystems of the 
        Pacific Coast. We are dedicated to fostering a sustainable future by supporting cutting-edge research and innovative conservation 
        initiatives that promote the understanding and protection of the region's unique marine life, with a particular emphasis on the 
        behavioral patterns of tiger sharks and dolphins.
        <br><br>
        Our commitment to safeguarding the health of the Pacific Ocean and its diverse inhabitants is rooted in our belief that these 
        ecosystems are a treasure to be cherished and preserved for future generations. By fostering collaborations among scientists, 
        local communities, policymakers, and other stakeholders, we strive to promote harmonious coexistence between humans and marine 
        life, to ensure the longevity of these precious resources.
        <br><br>
        Through our strategic investments in research, education, and community engagement, we aim to inspire a sense of stewardship and 
        responsibility for the marine environment, as well as encourage a deeper appreciation for the wonder and beauty of the Pacific 
        Coast's underwater world. Together, we can forge a brighter future for the ocean's treasures and create a lasting legacy of marine 
        preservation.
      </p>
    </div>
  </div>
  <div class="container slate">
    <div class="mission max">
      <h2>PARTNERSHIPS</h2>
      <img src="../assets/dots-white.svg" alt="" class="dots">
      <a href="https://beneaththewaves.org/" target="_blank"><img src="../assets/BTWLogo-white.svg" alt="" ></a>
    </div>
  </div>

</template>

<script>

export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  components: {
    
  }
}
</script>
